import { createActions } from "redux-actions"

export const { language } = createActions({
  LANGUAGE: {
    SET_LANGUAGE_ISO639: lngISO639 => ({ lngISO639 }),

    SET_COUNTRIE_CURRENT: countrieContenful => ({ countrieContenful }),
    SET_LANGUAGE_CURRENT: languageContenful => ({ languageContenful }),

    REDIRECT_NEW_LNG: (lngCurrent, countrieCurrent) => ({
      lngCurrent,
      countrieCurrent,
    }),
  },
})
