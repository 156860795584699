import { all, select, takeLatest } from "redux-saga/effects"

import * as StorageNewLead from "../../@common/storage/newLead"
import { liftersView as liftersViewActions } from "./LiftersViewActions"

function* setNewLead() {
  const { newLead } = yield select(state => state.liftersView)
  StorageNewLead.setNewLead(newLead)
}

function* ActionWatcher() {
  yield takeLatest(liftersViewActions.setNewLead, setNewLead)
}

export default function* rootSaga() {
  yield all([ActionWatcher()])
}
