import { handleActions } from "redux-actions"

export const INITIAL_STATE = {
  error: {
    sendForm: false,
  },
  success: {
    sendForm: false,
  },
}

const reducer = handleActions(
  {
    HUBSPOT: {
      SEND_FORM: (state) => ({
        ...state,
        error: INITIAL_STATE.error,
        success: INITIAL_STATE.success
      }),
      SEND_FORM_RESPONSE: {
        throw(state, { payload: { message } }) {
          return {
            ...state,
            error: { ...state.error, sendForm: message },
          }
        },
      },

      SET_SUCCESS: (state, { payload: { key, newState } }) => ({
        ...state,
        success: { ...state.success, [key]: newState }
      }),
    },
  },
  INITIAL_STATE
)

export default reducer
