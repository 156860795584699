import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import { en } from "./resources/en"
import { es } from "./resources/es"
import { pt } from "./resources/pt"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      //en: { translation: en },
      es: { translation: es },
      pt: { translation: pt },
    },
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
