import { handleActions } from "redux-actions"

export const INITIAL_STATE = {
  modalPolicyPrivacity: false
}

const reducer = handleActions(
  {
    MODALS: {
      SET_MODALS: (state, { payload: { modalKey, newState } }) => ({
        ...state,
        [modalKey]: newState
      }),
    },
  },
  INITIAL_STATE
)

export default reducer
