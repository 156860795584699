const general = {
  name: "Nome",
  lastname: "sobrenome",
  full_name: "Nome completo",
  name_organization: "Nome da empresa",
  name_contact: "Nome do contato",
  label_phone: "Número de telefone",
  document: "Documento",
  nit: "Documento",
  email: "e-mail",
  city: "Cidade",
  city_operation: "Cidade de operação",
  available: "Disponibilidade",
  label_person_natural: "Você é pessoa física ou jurídica",
  natural: "Física",
  juridical: "Jurídica",
  plate: "Placa",
  type_license: "Tipo de licença",
  select_options: "Selecione uma opção",
  step1: "Passo 1",
  step2: "Passo 2",
  "monday-friday": "Segunda-Sexta",
  "saturday-sunday": "Sábado-Domingo",
  fulltime: "Todos os dias",
  countries: "Países",
  languages: "Idioma",
  success: "Êxito",
  excellence: "Excelência",
  compliance: "Conformidade",
  hearth: "Coração",
  professionalism: "Profissionalismo",
  header: "Cabeçalhos",
  call_resource: "Chamar recurso",
  request: "Solicitação",
  description: "Descrição",
  documentation: "Documentação",
  result: "Resultado",
  attributes: "Atributos",
  follous: "Siga-nos",
  i_am: "Eu sou",
  i_want: "e quero",
  we_talk_you: "Falamos com você",
  community_lifters: "COMUNIDADE DE LIFTERS",
  community: "Comunidade",
  good: "Muito bom",
  clickme: "Clique aqui",
  may_also_like: "Também pode te interessar",
  company: "Companhia",
  confirm_accept: "Confirmo que conheço e aceito as",
  may_interest_you: "Pode te interesar",
  study_cases: "Estudos de caso",
  industry: 'Indústria',
  name_business: 'Nome da empresa',
  information_business: 'Informação da empresa',
  information_personal: 'Informação pessoal',
  developers: 'Developers',
  resources: 'Recursos',
  aboutus: 'Nós',
  of: 'de',
  see_demo_group: 'Veja a demonstração do grupo'
}

const generators = {
  we_allies: "Nossos Aliados, transportamos juntos:",
}

const lifters_thanksyou = {
  information_send_email: "A informação será enviada por e-mail: ",
}

const button = {
  to_accept: "Aceitar",
  save_and_continue: "Salvar e continuar",
  send_and_continue: "Enviar e continuar",
  resume_enrollment: "Retomar inscrição",
  send: "Enviar",
  back_step_1: "Voltar ao Passo 1",
  see_demo: "Ver demo",
  see_case: "Ver caso",
  watch: "Ver",
  watch_more: "Ver mais",
  ilike: "Gostei",
  shared: "Compartilhar",
  contact: "Contatar",
  read_more: "Ler mais",
  read_more_here: "Leia mais aqui",
  see_less: "Ver menos",
  contact_us: "Contate-nos",
  revolutionize_deliveries: "Revolucione suas entregas",
  see_benefits: "Ver benefícios",
  suscription: "Inscrição",
  subscribe: "Inscreva-se",
  contact_to_agent: "Contate um consultor",
  see_most_tips: "Clique para ver mais dicas",
  read_recomendation: "Ler recomendações",
  read_article: "Ler artigo",
  want_lifter: "Quero ser Lifter",
  return: "Voltar",
  goToHome: 'Ir a home',
  signup_here: "Registre-se aqui",
  i_want_meet: "Quero agendar"
}

const question = {
  want_to_change: "Deseja mudar",
}

const card = {
  intercom: {
    hi: "Olá",
    title: "Ajudamos para que seu negócio cresça te conectando com seus clientes.",
    start: "Inicie una conversa",
    response: "Nossa equipe te responderá em instantes.",
    send: "Envie-nos uma mensagem",
  },
}

const form = {
  check_subscribed: "Ao se inscrever aceite as",
  privacy_policies: "Políticas de Privacidade",
  terms_conditions: "Termos e condições",
  thanks_signup: {
    title: 'Obrigado por se registrar!',
    description: 'Um de nossos consultores entrará em contato com você'
  },
  form_new_lead: {
    certification_other: "Especifique qual outra certificação tem",
  },
  form_home_demo: {
    delivery_per_month: "Entregas por mês",
    number_employees: "Número de funcionários da sua empresa",
    monthly_investment: "Qual é o seu investimento mensal?",
  },
  error: {
    required: "Este campo é obrigatório",
    email: "O formato não é um e-mail válido",
    phone_min: "O formato do telefone não é válido",
    NUMBER_OUT_OF_RANGE: "O número do telefone não é válido.",
    INVALID_EMAIL: "O e-mail não tem o formato válido."
  },
  placeholder: {
    name: "Insira seu nome",
    lastname: "Insira seu sobrenome",
    email: "Insira seu e-mail",
    name_company: "Insira o nome da empresa"
  }
}

export const pt = {
  form,
  general,
  button,
  card,
  question,
  generators,
  lifters_thanksyou,
}
