import { handleActions } from "redux-actions"

export const INITIAL_STATE = {
  sectionInternal: "banner-jobs"
}

const reducer = handleActions(
  {
    JOBS_VIEW: {
        NEXT_SECTION: (state, { payload: { section } }) => ({
        ...state,
        sectionInternal: section,
      }),
    },
  },
  INITIAL_STATE
)

export default reducer
