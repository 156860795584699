const general = {
  name: "Nombre",
  lastname: "Apellido",
  full_name: "Nombre completo",
  name_organization: "Nombre de la empresa",
  name_contact: "Nombre del contacto",
  label_phone: "Número de teléfono",
  document: "Cédula",
  nit: "NIT",
  email: "Correo electrónico",
  city: "Ciudad",
  city_operation: "Ciudad de operación",
  available: "Disponibilidad",
  label_person_natural: "¿Eres persona Natural o Jurídica?",
  natural: "Natural",
  juridical: "Jurídica",
  plate: "Placa",
  type_license: "Tipo de licencia",
  select_options: "Seleccione una opción",
  step1: "Paso 1",
  step2: "Paso 2",
  "monday-friday": "Lunes-Viernes",
  "saturday-sunday": "Sabado-Domingo",
  fulltime: "Tiempo completo",
  countries: "Países",
  languages: "Idioma",
  success: "Éxito",
  excellence: "Excelencia",
  compliance: "Cumplimiento",
  hearth: "Corazón",
  professionalism: "Profesionalismo",
  header: "Encabezados",
  call_resource: "Llamar recurso",
  request: "Solicitud",
  description: "Descripción",
  documentation: "Documentación",
  result: "Resultado",
  attributes: "Atributos",
  follous: "Síguenos",
  i_am: "Yo soy",
  i_want: "y quiero",
  we_talk_you: "Te hablamos a ti",
  community_lifters: "COMUNIDAD DE LIFTERS",
  community: "Comunidad",
  good: "Muy bien",
  clickme: "Haz click",
  may_also_like: "También te puede interesar",
  company: "Compañia",
  confirm_accept: "Confirmo que conozco y acepto las",
  may_interest_you: "Te puede interesar",
  study_cases: "Casos de estudio",
  name_business: 'Nombre de empresa',
  information_business: 'Información de la empresa',
  information_personal: 'Información del personal',
  developers: 'Developers',
  resources: 'Recursos',
  aboutus: 'Nosotros',
  of: 'de',
  see_demo_group: 'Ver demo en grupo', //Falta pt
  title_politics_privacity: 'POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES' //Falta pt
}

const generators = {
  we_allies: "Nuestros Aliados, transportamos juntos:",
}

const lifters_thanksyou = {
  information_send_email: "La información será enviada al correo: ",
}

const button = {
  to_accept: "Aceptar",
  save_and_continue: "Guardar y continuar",
  send_and_continue: "Enviar y continuar",
  resume_enrollment: "Reanudar inscripción",
  send: "Enviar",
  back_step_1: "Regresar a Paso 1",
  see_demo: "Ver demo",
  see_case: "Ver caso",
  watch: "Ver",
  watch_more: "Ver más",
  ilike: "Me gusta",
  shared: "Comparte",
  contact: "Contactar",
  read_more: "Leer más",
  read_more_here: "Leer más aquí",
  see_less: "Ver menos",
  contact_us: "Contáctanos",
  revolutionize_deliveries: "Revoluciona tus entregas",
  see_benefits: "Ver beneficios",
  suscription: "Suscribirse",
  subscribe: "Suscribete",
  contact_to_agent: "Contactar a un agente",
  see_most_tips: "Da clic para ver más tips",
  read_recomendation: "Leer recomendaciones",
  read_article: "Leer articulo",
  want_lifter: "Quiero ser lifter",
  return: "Volver",
  goToHome: 'Ir a home',
  signup_here: "Registrate aquí",
  i_want_meet: "Quiero agendarme"
}

const question = {
  want_to_change: "¿Deseas cambiarlo?",
}

const card = {
  intercom: {
    hi: "Hola",
    title: "Ayudamos a que su negocio crezca conectándolo con sus clientes.",
    start: "Inicia una conversación",
    response: "El equipo suele responder en unas pocas horas.",
    send: "Envíanos un mensaje",
  },
}

const form = {
  check_subscribed: "Al suscribirte aceptas las",
  privacy_policies: "Políticas de Privacidad",
  terms_conditions: "Términos y Condiciones",
  thanks_signup: {
    title: '¡Gracias por registrarte!',
    description: 'Uno de nuestros asesores te contactará'
  },
  form_new_lead: {
    certification_other: "Especifique que otra certificación tiene",
  },
  form_home_demo: {
    delivery_per_month: "Entregas por mes",
    number_employees: "Número de empleados en tu compañía",
    monthly_investment: "¿Cuál es tu inversión mensual?",
  },

  error: {
    required: "Este campo es requerido",
    email: "El formato no es un correo valido",
    phone_min: "El formato del teléfono no es valido",
    //hubspot errors
    NUMBER_OUT_OF_RANGE: "El número de teléfono no es valido.",
    INVALID_EMAIL: "El correo no tiene el formato valido."
  },
  placeholder: {
    name: "Ingresa tu nombre",
    lastname: "Ingresa tu apellido",
    email: "Ingresa tu correo",
    name_company: "Ingresa nombre de la empresa"
  }
}

export const es = {
  form,
  general,
  button,
  card,
  question,
  generators,
  lifters_thanksyou,
}
