import { all, takeLatest, put } from "redux-saga/effects"

import { wordpress as wordpressActions } from "./WordpressActions"
import { URL_BLOG, HUBSPOT_EMAIL_TEST } from '../../@common/environments/environment'

function* getArticles({ payload }) {
  const url = new URL(`${URL_BLOG}/wp-json/wp/v2/posts`)

  if (payload.params)
    Object.keys(payload.params).forEach(key => {
      if (payload.params[key])
        url.searchParams.append(key, payload.params[key])
    })

  const response = yield fetch(url, {
    method: "GET",
    headers: { Accept: "application/json" },
  }).then(data => data.json())

  if (!response) {
    const err = new TypeError("ERROR_GET_ARTICLES")
    yield put(wordpressActions.getArticlesResponse(err))
  }

  yield put(wordpressActions.getArticlesResponse(response, payload.name))
}

function* ActionWatcher() {
  yield takeLatest(wordpressActions.getArticles, getArticles)
}

export default function* rootSaga() {
  yield all([ActionWatcher()])
}
