import "./src/sass/main.scss"
import "./src/i18n/i18n"
import AOS from "aos"
import "aos/dist/aos.css" // You can also use <link> for styles
// ..
AOS.init()
export { default as wrapRootElement } from "./src/store/ReduxWrapper"

export const onInitialClientRender = () => {
  setTimeout(function() {
    const loader = document.getElementById("___loader")
    if(loader)
      document.getElementById("___loader").style.display = "none"
  }, 2000)
}