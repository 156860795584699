import { handleActions } from "redux-actions"

export const INITIAL_STATE = {
  isFormLeadSteps: false,
  newLead: undefined,
  ignoreDataLead: false,
}

const reducer = handleActions(
  {
    LIFTERS_VIEW: {
      SET_NEW_LEAD: (state, { payload: { data } }) => ({
        ...state,
        newLead: data,
      }),

      SET_FORM_STEPS: (state, { payload: { newState, options } }) => ({
        ...state,
        isFormLeadSteps: newState,
        ignoreDataLead: options?.ignoreDataLead,
      }),
    },
  },
  INITIAL_STATE
)

export default reducer
