import { createActions } from "redux-actions"

export const { hubspot } = createActions({
  HUBSPOT: {
    SEND_FORM: (formId, keys, params) => ({ formId, keys, params }),
    SEND_FORM_RESPONSE: (error) => ({ error }),

    SET_SUCCESS: (key, newState) => ({ key, newState })
  },
})
