import { takeLatest, all, select, put } from "redux-saga/effects"
import { navigate } from "gatsby"

import i18n from "../../i18n/i18n"
import { language as languageActions } from "./LanguageActions"

function* setLanguageISO639({ payload: { lngISO639 } }) {
  yield i18n.changeLanguage(lngISO639.split("-")[0])
}

function* setLanguageCurrent({ payload: { languageContenful } }) {
  yield i18n.changeLanguage(languageContenful)
}

function* redirectNewLng({ payload: { lngCurrent, countrieCurrent } }) {
  const { languageDefault, languageISO639 } = yield select(
    state => state.language
  )
  let newPath
  const newLngISO639 = `${lngCurrent}-${countrieCurrent}`

  if (languageDefault === languageISO639) {
    const [, ...paths] = window.location.pathname.split("/")
    newPath = paths.join("/")
  } else {
    const [, , ...paths] = window.location.pathname.split("/")
    newPath = paths.join("/")
  }

  if (newLngISO639 === languageDefault) navigate(`/${newPath}`)
  else navigate(`/${newLngISO639}/${newPath}`)

  yield put(languageActions.setCountrieCurrent(countrieCurrent))
  yield put(languageActions.setLanguageCurrent(lngCurrent))
  yield put(languageActions.setLanguageIso639(newLngISO639))
}

function* ActionWatcher() {
  yield takeLatest(languageActions.setLanguageIso639, setLanguageISO639)
  yield takeLatest(languageActions.setLanguageCurrent, setLanguageCurrent)
  yield takeLatest(languageActions.redirectNewLng, redirectNewLng)
}

export default function* rootSaga() {
  yield all([ActionWatcher()])
}
