import { handleActions } from "redux-actions"

export const INITIAL_STATE = {
  countries: undefined,
  loading: {
    getCountries: false,
  },
  error: {
    getCountries: undefined,
  },
}

const reducer = handleActions(
  {
    POETRI: {
      GET_COUNTRIES: state => ({
        ...state,
        loading: { ...state.loading, getCountries: true },
        error: { ...state.error, getCountries: undefined },
      }),
      GET_COUNTRIES_RESPONSE: {
        next(state, { payload: { countries } }) {
          return {
            ...state,
            countries,
            loading: { ...state.loading, getCountries: false },
          }
        },
        throw(state, { error }) {
          return {
            ...state,
            loading: { ...state.loading, getCountries: false },
            error: { ...state.error, getCountries: error },
          }
        },
      },
    },
  },
  INITIAL_STATE
)

export default reducer
