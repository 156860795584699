import { combineReducers } from "redux"

import reducerLanguage from "../services/Language/LanguageReducer"
import reducerLiftersView from "../services/LiftersView/LiftersViewReducer"
import reducerPoetri from "../services/Poetri/PoetriReducer"
import reducerJobs from "../services/JobsView/JobsViewReducer"
import reducerWordpress from "../services/Wordpress/WordpressReducer"
import reducerHubspot from "../services/Hubspot/HubspotReducer"
import reducerModals from "../services/Modals/ModalsReducer"

const appReducer = combineReducers({
  language: reducerLanguage,
  liftersView: reducerLiftersView,
  poetri: reducerPoetri,
  jobsView: reducerJobs,
  wordpress: reducerWordpress,
  hubspot: reducerHubspot,
  modals: reducerModals
})

export default appReducer
