import { handleActions } from "redux-actions"
import i18n from "../../../config/i18n"

const languages = [...Object.keys(i18n)]
let lngDefault

Object.values(i18n).forEach(item => {
  languages.push(...item.map(_item => _item.locale))

  const isExist = item.find(_item => _item.default)
  if (isExist) lngDefault = isExist
})

let languageRoot

languages.forEach(lng => {
  if (typeof window === "undefined") languageRoot = lngDefault.locale
  else {
    const { pathname } = window.location

    if (pathname.includes(`/${lng}/`)) languageRoot = lng
    else if (!languageRoot) languageRoot = lngDefault.locale
  }
})

export const INITIAL_STATE = {
  languageDefault: lngDefault.locale,
  languageISO639: languageRoot,

  countrieCurrent: undefined,
  languageCurrent: undefined,
}

const reducer = handleActions(
  {
    LANGUAGE: {
      SET_LANGUAGE_ISO639: (state, { payload: { lngISO639 } }) => ({
        ...state,
        languageISO639: lngISO639,
      }),
      SET_COUNTRIE_CURRENT: (state, { payload: { countrieContenful } }) => ({
        ...state,
        countrieCurrent: countrieContenful,
      }),
      SET_LANGUAGE_CURRENT: (state, { payload: { languageContenful } }) => ({
        ...state,
        languageCurrent: languageContenful,
      }),
    },
  },
  INITIAL_STATE
)

export default reducer
