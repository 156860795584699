module.exports = {
  /*en: [
    { locale: `en-US`, dateFormat: `DD/MM/YYYY` },
    { locale: `en-CO`, dateFormat: `DD.MM.YYYY` },
    { locale: `en-CH`, dateFormat: `DD.MM.YYYY` },
    { locale: `en-EC`, dateFormat: `DD.MM.YYYY` },
    { locale: `en-MX`, dateFormat: `DD.MM.YYYY` },
    { locale: `en-BR`, dateFormat: `DD.MM.YYYY` },
  ],*/
  es: [
    { locale: `es-CO`, dateFormat: `DD.MM.YYYY`, default: true },
    { locale: `es-CH`, dateFormat: `DD.MM.YYYY` },
    { locale: `es-EC`, dateFormat: `DD.MM.YYYY` },
    { locale: `es-MX`, dateFormat: `DD.MM.YYYY` },
    { locale: `es-BR`, dateFormat: `DD.MM.YYYY` },
  ],
  pt: [
    { locale: `pt-CO`, dateFormat: `DD.MM.YYYY` },
    { locale: `pt-CH`, dateFormat: `DD.MM.YYYY` },
    { locale: `pt-EC`, dateFormat: `DD.MM.YYYY` },
    { locale: `pt-MX`, dateFormat: `DD.MM.YYYY` },
    { locale: `pt-BR`, dateFormat: `DD.MM.YYYY` },
  ],
}
