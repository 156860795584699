import { all, takeLatest, put } from "redux-saga/effects"

import { hubspot as hubspotActions } from "./HubspotActions"

function* sendForm({ payload }) {
  const data = {
    fields: []
  }
  Object.keys(payload.params).map((key) => {
    if ((!payload.keys || payload.keys.includes(key)) && payload.params[key])
      data.fields.push({ name: key, value: payload.params[key] })
  })

  const response = yield fetch(`https://api.hsforms.com/submissions/v3/integration/submit/4838246/${payload.formId}`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Accept: "application/json"
    },
    body: JSON.stringify(data)
  }).then(data => data.json())

  if (!response) {
    const err = new TypeError("ERROR_HUBSPOT_SEND_FORM")
    yield put(hubspotActions.sendFormResponse(err))
  } else if (response.status === 'error') {
    const err = new TypeError(response.errors[0].errorType)
    yield put(hubspotActions.sendFormResponse(err))
  } else {
    yield put(hubspotActions.setSuccess('sendForm', true))
    yield put(hubspotActions.sendFormResponse(response))
  }
}

function* ActionWatcher() {
  yield takeLatest(hubspotActions.sendForm, sendForm)
}

export default function* rootSaga() {
  yield all([ActionWatcher()])
}
