import { fork, all } from "redux-saga/effects"

import LanguageSaga from "../services/Language/LanguageSaga"
import PoetriSaga from "../services/Poetri/PoetriSaga"
import LiftersViewSaga from "../services/LiftersView/LiftersViewSaga"
import WordpressSaga from "../services/Wordpress/WordpressSaga"
import HubspotSaga from "../services/Hubspot/HubspotSaga"

export default function* rootSaga() {
  yield all([
    fork(LanguageSaga),
    fork(PoetriSaga),
    fork(LiftersViewSaga),
    fork(WordpressSaga),
    fork(HubspotSaga)
  ])
}
