import { takeLatest, all, put } from "redux-saga/effects"

import { poetri as poetriActions } from "./PoetriActions"

function* getCountries() {
  // const _object = ['name', 'flag', 'alpha2code', 'callingcodes']

  const data = [
    {
      alpha2code: "CO",
      callingcodes: ["57"],
      flag: "https://restcountries.eu/data/col.svg",
      name: "Colombia",
    },
    {
      alpha2code: "CL",
      callingcodes: ["56"],
      flag: "https://restcountries.eu/data/chl.svg",
      name: "Chile",
    },
    {
      alpha2code: "EC",
      callingcodes: ["593"],
      flag: "https://restcountries.eu/data/ecu.svg",
      name: "Ecuador",
    },
    {
      alpha2code: "MX",
      callingcodes: ["52"],
      flag: "https://restcountries.eu/data/mex.svg",
      name: "Mexico",
    },
    {
      alpha2code: "BR",
      callingcodes: ["55"],
      flag: "https://restcountries.eu/data/bra.svg",
      name: "Brazil",
    },
  ]

  yield put(poetriActions.getCountriesResponse(data))
}

function* ActionWatcher() {
  yield takeLatest(poetriActions.getCountries, getCountries)
}

export default function* rootSaga() {
  yield all([ActionWatcher()])
}
