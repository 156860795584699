import { handleActions } from "redux-actions"

export const INITIAL_STATE = {
  articles: undefined,
  error: {
    getArticle: false,
  },
}

const reducer = handleActions(
  {
    WORDPRESS: {
      GET_ARTICLES_RESPONSE: {
        next(state, { payload: { articles, name } }) {
          return { ...state, [name ? name : 'articles']: articles }
        },
        throw(state, { error }) {
          return {
            ...state,
            error: { ...state.error, getArticle: error },
          }
        },
      },
    },
  },
  INITIAL_STATE
)

export default reducer
