const key = "new-lead"

export const isNewLead = () => {
  return localStorage.getItem(key) !== null
}

export const getNewLead = () => {
  return localStorage.getItem(key)
}

export const setNewLead = value => {
  return localStorage.setItem(key, JSON.stringify(value))
}

export const removeNewLead = () => {
  return localStorage.removeItem(key)
}

export const updateNewLead = value => {
  let lastStorageLead = localStorage.getItem(key)
  if (!lastStorageLead) localStorage.setItem(key, JSON.stringify({ ...value }))
  else {
    lastStorageLead = JSON.parse(lastStorageLead)
    localStorage.setItem(key, JSON.stringify({ ...lastStorageLead, ...value }))
  }
}
